// ag-grid.scss defines structural styles and should be imported first
@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";
@import "~ag-grid-community/src/styles/webfont/agGridMaterialFont.scss";

// Fonts from https://fontawesome.com/
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

@font-face {
  font-family: "icomoon";
  src: url("./fonts/icomoon.eot?82kgqt");
  src: url("./fonts/icomoon.eot?82kgqt#iefix") format("embedded-opentype"),
    url("./fonts/icomoon.ttf?82kgqt") format("truetype"),
    url("./fonts/icomoon.woff?82kgqt") format("woff"),
    url("./fonts/icomoon.svg?82kgqt#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


@font-face {
  font-family: 'Menlo Regular';
  src: url("./fonts/Menlo-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 100;
  src: url('./fonts/SFProText/SF-Pro-Text-Ultralight.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: italic;
  font-weight: 100;
  src: url('./fonts/SFProText/SF-Pro-Text-UltralightItalic.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/SFProText/SF-Pro-Text-Thin.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/SFProText/SF-Pro-Text-ThinItalic.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/SFProText/SF-Pro-Text-Regular.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/SFProText/SF-Pro-Text-RegularItalic.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/SFProText/SF-Pro-Text-Medium.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: italic;
  font-weight: 00;
  src: url('./fonts/SFProText/SF-Pro-Text-MediumItalic.otf') format('opentype');
}

@font-face {
  
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/SFProText/SF-Pro-Text-Semibold.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/SFProText/SF-Pro-Text-SemiboldItalic.otf') format('opentype');
}

@font-face {
  
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/SFProText/SF-Pro-Text-Bold.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/SFProText/SF-Pro-Text-BoldItalic.otf') format('opentype');
}

@font-face {
  
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/SFProText/SF-Pro-Text-Heavy.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/SFProText/SF-Pro-Text-HeavyItalic.otf') format('opentype');
}

@font-face {
  
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/SFProText/SF-Pro-Text-Black.otf') format('opentype');
}
@font-face {
  
  font-family: 'SF Pro Text';
  font-style: italic;
  font-weight: 900;
  src: url('./fonts/SFProText/SF-Pro-Text-BlackItalic.otf') format('opentype');
}

/* work-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/work-sans-v19-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* work-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/work-sans-v19-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

$gridRowHoverColor: rgb(189, 64, 189);

.ag-theme-material {
  @include ag-theme-material(
    (
      icon-font-family: "agGridMaterial",
      icons-data: null,
    )
  );

  .ag-icon {
    font-size: 24px;
    color: #656565;
  }

  // ***************************************
  // ******* modified header styles ********
  // ***************************************

  // modified header menu icon
  .ag-header-cell .ag-icon-menu::before {
    font-family: "icomoon" !important;
    content: "\e900";
    color: #b6babe;
  }

  // modified header menu icon when filtering
  .ag-header-cell.ag-header-cell-filtered .ag-icon-menu::before {
    font-family: "icomoon" !important;
    content: "\e901" !important;
    color: #5c5c5c;
  }

  // hide additional filter icon that appears in header when filtering
  .ag-header-cell-label .ag-icon-filter::before {
    display: none !important;
  }

  .ag-side-button-button .ag-side-button-icon-wrapper .ag-icon-filter::before {
    font-family: "icomoon" !important;
    content: "\e900" !important;
  }

  .ag-side-button-button .ag-side-button-label {
    margin-top: 5px;
  }

  .ag-root-wrapper-body {
    border: 1px solid #dadde0;
    border-radius: 5px;
  }

  .ag-root-wrapper-body .ag-side-bar-right {
    border-left: 1px solid #dadde0;
  }

  .ag-root-wrapper-body .ag-side-bar-right .ag-side-buttons {
    width: unset;
    padding: 0 5px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

  }

  .ag-root-wrapper-body
    .ag-side-bar-right
    .ag-side-buttons
    .ag-side-button-button {
    min-height: 100px;
  }

  .ag-ltr .ag-side-bar-right .ag-selected {
    background-color: #efefef;
    margin: 0 -5px;
  }

  .ag-ltr .ag-side-bar-right .ag-selected .ag-side-button-button {
    border-left: unset;
    // margin: 0 -5px;
  }

  .ag-tool-panel-wrapper {
    border-right: 1px solid #dadde0;
  }

  .ag-tool-panel-wrapper .ag-hidden {
    border-right: none;
  }

  .ag-tool-panel-wrapper {
    width: 320px;
  }

  .ag-tool-panel-wrapper .ag-react-container {
    width: 100%;
  }

  .ag-checkbox-input-wrapper.ag-checked::after {
    color: black;
  }

  .ag-tool-panel-wrapper {
    background-color: white;
  }

  .ag-filter-apply-panel {
    padding: 2px 16px 12px;
  }

  .ag-standard-button {
    text-transform: capitalize;
    color: #2767e9;
    font-size: 14px;
  }
  .ag-row {
    width: 100vw !important;
  }
  .ag-floating-bottom {
    background-color: #EFEFEF;
  }
  .ag-floating-bottom .ag-pinned-left-floating-bottom .ag-row{
    background-color: #EFEFEF;
  }
  .ag-floating-bottom .ag-floating-bottom-viewport .ag-floating-bottom-container .ag-row {
    background-color: #EFEFEF;
  }
  .ag-selection-checkbox {
    margin-right: 0px !important;
  }
}

/* Date Range Picker */
#date-range-picker > div > div:first-child {
  width: 0;
  height: 0;
}

#date-range-picker
  > div
  > div
  > div
  > div
  > div:nth-child(1)
  > div:nth-child(1) {
  display: none;
}

#date-range-picker #menu- > div:nth-child(3) {
  max-height: 180px;
}

#date-range-picker #menu- > div:nth-child(3) > ul {
  max-height: 180px;
  overflow-y: auto;
}

#date-range-picker #menu- > div:nth-child(3) > ul > li {
  min-height: 0;
}

@mixin filter-count-icon {
  background: #5c5c5c;
  color: white;
  height: 20px;
  width: 24px;
  border-radius: 50%;
  text-align: center;
  font-family: "arial";
  font-size: 14px;
}

.menu-filter-count {
  @include filter-count-icon;
  margin-left: 8px;
  padding: 1px;
}

.sidebar-filter-count {
  @include filter-count-icon;
  position: absolute;
  padding-top: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(90deg);
  margin-bottom: 40px;
}

.ag-text-field-input {
  border: 1px solid #c4c4c4 !important;
}
.ag-text-field-input:hover {
  border: 1px solid #656565 !important;
}

.ag-text-field-input:focus {
  border: 2px solid #656565 !important;
}
.ag-number-field-input {
  border: 1px solid #c4c4c4 !important;
}
.ag-number-field-input:hover {
  border: 1px solid #656565 !important;
}

.ag-number-field-input:focus {
  border: 2px solid #656565 !important;
}
.ag-picker-field-wrapper {
  height: 40px !important;
  border-radius: 0px !important;
}
.ag-filter-virtual-list-item {
  margin-left: 5px;
}
.row-favorited {
  background-color: #DEF8D6 !important;
}
canvas
{
    width: 100% !important;
}